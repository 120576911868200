/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Varela+Round&display=swap');
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "flags.css";
@import "primeng/resources/primeng.css";
* {

}


.cormorant {
  font-family: 'Cormorant Garamond', serif;
}

.quicksand {
  //font-family: "Quicksand", sans-serif;
  //font-family: "Varela Round", sans-serif;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  //font-weight: 400;
  //font-style: normal;
}

.italic {
  font-style: italic;
}

.bold-400 {
  font-weight: 400;
}

.bold-600 {
  font-weight: 600;
}

.font-0-9 {
  font-size: 0.9rem;
}

.font-1-8 {
  font-size: 1.8rem;
}

.font-5-2 {
  font-size: 5.2rem;
}

.line-height-1 {
  line-height: 1em;
}


body {
  //font-family: "Quicksand", sans-serif;
  font-family: "Comfortaa", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8em;
  background-attachment: fixed;
  background-color: #1A1A1A;
  background-image: url("assets/background-vectors.png");
  --e-bg-lazyload: url("assets/background-vectors.png");
  background-position: center center;
  background-size: cover;
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

::-webkit-scrollbar {
  width: 10px!important;
  height: 10px!important;
}

::-webkit-scrollbar-track {
  background: #686868!important;
}

::-webkit-scrollbar-thumb {
  background-color: #D1AF77!important;
  border-radius: 6px!important;
  border: 3px solid #D1AF77!important;
}

a {
  text-decoration: none;
  color: #F2F2F2;
}

.mh {
  height: 100%;
}

.mw {
  width: 100%;
}

.all-area {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
}


.largeText {
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.1em;
  font-family: 'Cormorant Garamond', serif;
}

.subtitle {
  //font-family: "Quicksand", sans-serif;
  font-family: "Comfortaa", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 4.5px;
}

.header-text {
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2em;
}

.colorWhite {
  color: #F9F9F9;
}

.colorYellow {
  color: #D1AF77;
}

.colorGray {
  color: #272727;
}

.colorGrayLight {
  color: #686868;
}

.colorRed {
  color: #CF5D5D;
}

@media (min-width: 1400px) {
  .container{
    max-width: 1400px;
  }
}


@media (max-width: 768px) {
  p {
    font-size: 0.9rem;
  }

  .mh {
    height: auto;
  }

  .font-1-8 {
    font-size: 1.2rem;
  }

  .font-5-2 {
    font-size: 3.2rem;
  }

  .largeText {
    font-size: 2.7rem;
  }
}
